<template>
  <div class="bigBox">
    <!-- 头部版本及下载版权开发者信息 -->
    <div class="tabNew" v-if="tabBar" :class="navBarFixed ? 'navBarFixed' : ''">
      <div class="tabTop">
        <div>
          <ul>
            <img class="hongshi" src="../../../../static/common/wap/hongshi.png" alt="" />
            <p>
              <span style="font-weight:600;font-size:0.16rem;">红狮启富</span>
              <span>应用版本：5.0.0</span>
              <span>更新时间：2024年09月02日</span>
            </p>
          </ul>
          <img @click="downLoadApp" class="downLoadApp" src="../../../../static/common/wap/downloadApp.png" alt="" />
        </div>
      </div>
      <p class="tabBottom">
        <span>应用权限:查看权限 隐私权限: 查看权限</span>
        <span>APP开发者: 香港红狮集团有限公司 应用名称:红狮启富</span>
      </p>
      <img
        @click="
          () => {
            tabBar = false
          }
        "
        class="closer"
        src="../../../../static/common/wap/closer.png"
        alt=""
      />
    </div>
    <!-- 头部版本及下载版权开发者信息结束 -->
    <div class="box" :class="!tabBar ? 'closerTabBar' : ''">
      <div class="top"></div>
      <div class="cont">
        <div class="section_title">
          <img src="/activity/market/title.png" class="title" alt="" />
        </div>
        <div class="con1"></div>
        <div class="con2"></div>
        <div class="con3"></div>
        <div class="con4"></div>
        <div class="gold">
          <div class="title"></div>
          <div class="gold15"></div>
          <div class="qrcode"></div>
        </div>
        <div class="left1"></div>
        <div class="right1"></div>
        <div class="left2"></div>
      </div>
    </div>
    <div class="bottomBar" v-if="tabBar">
      <p>
        <img src="../../../../static/common/wap/call.png" alt="" />
        <span @click="getLive800">咨询客服</span>
      </p>
      <p>
        <img src="../../../../static/common/wap/dollars.png" alt="" />
        <span @click="goLogin">立领$10000+$500新课礼包</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'voucherNewWap',
  data() {
    return {
      model: 0,
      scroll: '',
      navBarFixed: false,
      isApp: false,
      tabBar: false, //隐藏弹框
    }
  },
  created() {
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow', false)
    this.$emit('controlBottom', false)
    // 获取浏览器参数model
    if (this.$route.query.model) {
      this.model = this.$route.query.model
    }
    if (this.$route.query.isApp) {
      this.$emit('controlBottom', false)
      this.isApp = true
    }
  },
  methods: {
    // 跳客服
    live800() {
      window.open(this.$parent.live800Url)
      // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377')
    },
    goLogin() {
      window.open('https://www.rlcvipltd.net/uc/login')
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    getLive800() {
      window.open(this.$parent.live800Url)
    },
    // 点击下载app
    downLoadApp() {
      var u = navigator.userAgent
      if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
        //苹果
        try {
          window.webkit.messageHandlers.gotoRegisterReal.postMessage('1')
        } catch (error) {
          window.open('https://apps.apple.com/cn/app/id1598072476')
        }
      } else {
        // 安卓
        try {
          window.android.gotoRegisterReal()
        } catch (error) {
          window.open('https://oss.0790jiaxiao.com/HS/software/hs_9999.apk')
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
.bigBox {
  height: auto;
  .tab {
    width: 7.5rem;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    img {
      width: 120px;
    }
  }
  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  .box {
    width: 7.5rem;
    height: 28.72rem;
    position: relative;
    margin-bottom: 2.2rem;
    .top {
      width: 7.5rem;
      height: 11.68rem;
      margin: 0 auto;
      background: url('/activity/market/banner.png') no-repeat center;
      background-size: 100%;
    }
    .cont {
      width: 7.5rem;
      height: 57rem;
      background: url('/activity/market/bg.png') no-repeat center;
      background-size: 100% 100%;
      .section_title {
        width: 4.56rem;
        height: 1.33rem;
        margin: 0 auto;
        padding-top: 0.42rem;
        .title {
          width: 4.56rem;
          height: 0.51rem;
          line-height: 1.33rem;
        }
      }
      .con1 {
        height: 10rem;
        width: 6.61rem;
        background: url('/activity/market/1.png') no-repeat center;
        background-size: 100%;
        margin: 0 auto;
        margin-bottom: 0.3rem;
      }
      .con2 {
        height: 11.6rem;
        width: 6.61rem;
        background: url('/activity/market/2.png') no-repeat center;
        background-size: 100%;
        margin: 0 auto;
        margin-bottom: 0.3rem;
      }
      .con3 {
        height: 11.4rem;
        width: 6.61rem;
        background: url('/activity/market/3.png') no-repeat center;
        background-size: 100%;
        margin: 0 auto;
        margin-bottom: 0.3rem;
      }
      .con4 {
        height: 11.2rem;
        width: 6.61rem;
        background: url('/activity/market/4.png') no-repeat center;
        background-size: 100%;
        margin: 0 auto;
        margin-bottom: 0.3rem;
      }
      .gold {
        width: 7.5rem;
        padding-top: 0.3rem;
        .title {
          width: 5.73rem;
          height: 0.46rem;
          margin: 0 auto;
          background: url('/activity/market/15gold-title.png') no-repeat center;
          background-size: 100%;
          margin-bottom: 0.5rem;
        }
        .gold15 {
          width: 7.47rem;
          height: 2.8rem;
          margin: 0 auto;
          background: url('/activity/market/15gold.png') no-repeat center;
          background-size: 100%;
          margin-bottom: 0.6rem;
        }
        .qrcode {
          width: 5.73rem;
          height: 2.41rem;
          margin: 0 auto;
          background: url('/activity/market/qrcode.png') no-repeat center;
          background-size: 100%;
        }
      }
      .left1 {
        width: 0.95rem;
        height: 12.7rem;
        background: url('/activity/market/l.png') no-repeat center;
        background-size: 100%;
        position: relative;
        top: -48.5rem;
      }
      .right1 {
        width: 0.96rem;
        height: 12.55rem;
        background: url('/activity/market/r.png') no-repeat center;
        background-size: 100%;
        position: relative;
        left: 6.5rem;
        top: -49rem;
      }
      .left2 {
        width: 0.95rem;
        height: 12.7rem;
        background: url('/activity/market/l.png') no-repeat center;
        background-size: 100%;
        position: relative;
        top: -50rem;
      }
    }
  }
  .closerTabBar {
    margin-bottom: 0;
  }
  .tabNew {
    width: 7.5rem;
    height: 1.78rem;
    background: url('../../../../static/common/wap/tab.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 0.1rem 0 0.1rem;
    // position: relative;
    .tabTop {
      width: 100%;
      height: 1.02rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .hongshi {
            width: 0.75rem;
            margin: 0 0.2rem;
          }
          p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 0.12rem;
          }
        }
        .downLoadApp {
          width: 2.03rem;
          margin-top: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    }
    .tabBottom {
      font-size: 0.1rem;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 0.2rem;
    }
    .closer {
      width: 0.24rem;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .bottomBar {
    width: 7.5rem;
    box-sizing: border-box;
    padding: 0.25rem 0;
    background-color: #fff0ed;
    position: fixed;
    bottom: 0;
    p {
      width: 85%;
      height: 0.78rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img {
        width: 0.37rem;
        margin-right: 0.1rem;
      }
      span {
        font-size: 0.3rem;
      }
    }
    p:nth-child(1) {
      border: 1px solid #d52226;
      border-radius: 0.5rem;
      margin-bottom: 0.2rem;
      span {
        color: #d52226;
      }
    }
    p:nth-child(2) {
      background: url('../../../../static/common/wap/botBtn.png') no-repeat;
      background-size: 100% 100%;
      span {
        color: #fff;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
}
</style>
